'use client';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

import { useOAuth } from '@personly/libs-hooks';

import { useClients } from '../../hooks';

const OAuthValues = () => {
  const [clientId, setClientId] = useState('');
  const searchParams = useSearchParams();

  const { client, getClient } = useClients();
  const { responseType, state, redirectUri, scope } = useOAuth(
    searchParams,
    client
  );

  useEffect(() => {
    const id = searchParams.get('client_id');

    setClientId(id || process.env.NEXT_PUBLIC_AUTH_PROVIDER_MY);
  }, [searchParams]);

  useEffect(() => {
    if (!clientId) return;

    getClient(clientId);
  }, [getClient, clientId]);

  return (
    <>
      <input type="hidden" name="client_id" value={clientId} />
      <input type="hidden" name="redirect_uri" value={redirectUri} />
      <input type="hidden" name="response_type" value={responseType} />
      <input type="hidden" name="scope" value={scope} />
      <input type="hidden" name="state" value={state} />
    </>
  );
};

export default OAuthValues;
