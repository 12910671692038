'use client';

import Image from 'next/image';
import { string } from 'prop-types';

import { useSchemes } from '@personly/libs-providers';

const BrandScheme = ({ alt }) => {
  const { schemeHandle } = useSchemes();

  const src = `/images/brands/personly-heading-${schemeHandle.toLowerCase()}.svg`;

  return <Image src={src} width={104} height={26} alt={alt} />;
};

BrandScheme.propTypes = { alt: string.isRequired };

export default BrandScheme;
