'use client';

import { node, string } from 'prop-types';
import Link from 'next/link';
import { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';

const OAuthLink = ({ href, children }) => {
  const searchParams = useSearchParams();

  const hrefWithParams = useMemo(() => {
    return `${href}?${searchParams.toString()}`;
  }, [href, searchParams]);

  return (
    <Link
      prefetch={false}
      href={hrefWithParams}
      className="!text-footer-text-color"
    >
      {children}
    </Link>
  );
};

OAuthLink.propTypes = {
  href: string.isRequired,
  children: node.isRequired,
};

export default OAuthLink;
