import { useCallback } from 'react';

import { useNotifications, useSuspensions } from '@personly/libs-providers';
import { deploymentUrl } from '@personly/libs-urls';

const usePasswords = () => {
  const { createNotification } = useNotifications();
  const { suspensions } = useSuspensions();
  const { createSuspension, removeSuspension } = suspensions;

  const forgotPassword = useCallback(
    async ({ email, solution }, cb) => {
      createSuspension('FORGOT_PASSWORD');

      const url = deploymentUrl('/agon/forgotPassword');

      const data = new URLSearchParams();
      data.set('solution', solution);
      data.set('email', email);

      try {
        const response = await fetch(url, { method: 'POST', body: data });
        const { result } = await response.json();

        cb(result);
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      } finally {
        removeSuspension('FORGOT_PASSWORD');
      }
    },
    [createNotification, createSuspension, removeSuspension]
  );

  const resetPassword = useCallback(
    async ({ password, token }, cb) => {
      createSuspension('RESET_PASSWORD');

      const url = deploymentUrl('/agon/resetPassword');

      const data = new URLSearchParams();
      data.set('password', password);
      data.set('token', token);

      try {
        const response = await fetch(url, { method: 'POST', body: data });
        const { result } = await response.json();

        cb(result);
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      } finally {
        removeSuspension('RESET_PASSWORD');
      }
    },
    [createNotification, createSuspension, removeSuspension]
  );

  return { forgotPassword, resetPassword };
};

export default usePasswords;
