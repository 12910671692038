import { useCallback, useReducer } from 'react';

import { deploymentUrl } from '@personly/libs-urls';
import { useNotifications } from '@personly/libs-providers';

const initialState = {
  price: {
    base: { assetHandle: '', unitHandle: '' },
    quote: { assetHandle: '', unitHandle: '' },
    value: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PRICE_FETCHED': {
      return { ...state, price: { ...state.price, ...action.data } };
    }

    default:
      return state;
  }
};

const usePrices = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { createNotification } = useNotifications();

  const getPrice = useCallback(async () => {
    const url = deploymentUrl('/agon/getPrice');

    try {
      const response = await fetch(url);
      const { result } = await response.json();

      dispatch({ type: 'PRICE_FETCHED', data: result });
    } catch (err) {
      const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
      createNotification('ERROR', code);
    }
  }, [createNotification]);

  return { getPrice, price: state.price };
};

export default usePrices;
