'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useSearchParams } from 'next/navigation';

const PersonalStepsContext = createContext();

const PersonalStepsProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!searchParams.has('step')) return;
    const queryStep = parseInt(searchParams.get('step'), 10);
    if ([1, 2, 3].includes(queryStep)) setStep(queryStep);
  }, [searchParams, setStep]);

  const value = { step, setStep };

  return (
    <PersonalStepsContext.Provider value={value}>
      {children}
    </PersonalStepsContext.Provider>
  );
};

function usePersonalSteps() {
  const context = useContext(PersonalStepsContext);
  if (context === undefined)
    throw new Error('usePersonalSteps requires a context!');

  return context;
}

PersonalStepsProvider.propTypes = { children: node.isRequired };

export { PersonalStepsProvider, usePersonalSteps };
