'use client';

import { createContext, useContext, useMemo, useState } from 'react';
import { node } from 'prop-types';

import { useSuspensions } from '@personly/libs-providers';

import { useValidators } from './validators';

const boolean = new Map();
boolean.set(true, 'true');
boolean.set(false, 'false');

const RegisterContext = createContext();

const RegisterProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState(false);
  const { validate } = useValidators();

  const { blocked } = useSuspensions();

  const isDisabled = useMemo(
    () =>
      !validate('EMAIL_ADDRESS', email) ||
      !validate('PASSWORD', password) ||
      !privacyPolicyAccepted ||
      !termsOfServiceAccepted ||
      blocked,
    [
      email,
      password,
      blocked,
      privacyPolicyAccepted,
      termsOfServiceAccepted,
      validate,
    ]
  );

  const value = {
    isDisabled,
    email,
    setEmail,
    password,
    setPassword,
    privacyPolicyAccepted,
    setPrivacyPolicyAccepted,
    termsOfServiceAccepted,
    setTermsOfServiceAccepted,
  };

  return (
    <RegisterContext.Provider value={value}>
      <input
        type="hidden"
        name="privacy-policy-accepted"
        value={boolean.get(privacyPolicyAccepted)}
      />
      <input
        type="hidden"
        name="terms-of-service-accepted"
        value={boolean.get(termsOfServiceAccepted)}
      />
      {children}
    </RegisterContext.Provider>
  );
};

function useRegister() {
  const context = useContext(RegisterContext);
  if (context === undefined) throw new Error('useRegister requires a context!');

  return context;
}

RegisterProvider.propTypes = { children: node.isRequired };

export { RegisterProvider, useRegister };
