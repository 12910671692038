'use client';

import { array } from 'prop-types';

import { IconSelect } from '@personly/libs-widgets';
import { schemeIcon } from '@personly/libs-icons';
import { useOptions } from '@personly/libs-hooks';
import { useSchemes } from '@personly/libs-providers';

const SchemeDropdown = ({ schemes }) => {
  const { displaySchemeHandle, setSchemeHandle } = useSchemes();

  const { options: schemeOptions, selectedOption: selectedSchemeOption } =
    useOptions(
      schemes,
      displaySchemeHandle,
      ({ title }) => title,
      ({ id }) => id
    );

  const options = schemeOptions.map((scheme) => scheme.value);
  const selectedOption = selectedSchemeOption
    ? selectedSchemeOption.value
    : null;

  return (
    <IconSelect
      icon={schemeIcon}
      options={options}
      selectedOption={selectedOption}
      setSelectedOption={setSchemeHandle}
    />
  );
};

SchemeDropdown.propTypes = { schemes: array.isRequired };

export default SchemeDropdown;
