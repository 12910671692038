'use client';

import { node } from 'prop-types';
import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { useNotifications } from '@personly/libs-providers';

const Provider = ({ children }) => {
  const searchParams = useSearchParams();
  const { createNotification } = useNotifications();

  useEffect(() => {
    if (!searchParams.has('code')) return;
    createNotification('ERROR', searchParams.get('code'));
  }, [createNotification, searchParams]);

  return children;
};

Provider.propTypes = { children: node };

export default Provider;
