import { useCallback, useReducer } from 'react';

import { deploymentUrl } from '@personly/libs-urls';
import { useNotifications } from '@personly/libs-providers';

const initialState = { id: '', name: '', redirectUris: [''], scopes: [] };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLIENT_FETCHED': {
      return action.data;
    }

    default:
      return state;
  }
};

const useClients = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { createNotification } = useNotifications();

  const getClient = useCallback(
    async (id) => {
      const url = deploymentUrl('/agon/getClient');

      url.searchParams.set('id', id);

      try {
        const response = await fetch(url);
        const { result } = await response.json();

        dispatch({ type: 'CLIENT_FETCHED', data: result });
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      }
    },
    [createNotification]
  );

  return { client: state, getClient };
};

export default useClients;
