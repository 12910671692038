import { useCallback, useReducer } from 'react';

import { deploymentUrl } from '@personly/libs-urls';
import { useNotifications } from '@personly/libs-providers';

const initialState = {
  data: {
    REGISTRATION_PROMO: {
      quantity: { measure: { assetHandle: '', unitHandle: '' }, value: 0 },
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COUPONS_FETCHED': {
      const data = action.data.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur.quantity }),
        {}
      );

      return { ...state, data };
    }

    default:
      return state;
  }
};

const useCoupons = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { createNotification } = useNotifications();

  const getCoupons = useCallback(async () => {
    const url = deploymentUrl('/agon/getCoupons');

    try {
      const response = await fetch(url);
      const { result } = await response.json();

      dispatch({ type: 'COUPONS_FETCHED', data: result });
    } catch (err) {
      const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
      createNotification('ERROR', code);
    }
  }, [createNotification]);

  return { getCoupons, coupons: state.data };
};

export default useCoupons;
