import useClients from './clients';
import useCoupons from './coupons';
import useEmailAddresses from './emailAddresses';
import usePasswords from './passwords';
import usePersonals from './personals';
import usePrices from './prices';

export {
  useClients,
  useCoupons,
  useEmailAddresses,
  usePasswords,
  usePersonals,
  usePrices,
};
