'use client';

import { createContext, useContext, useMemo, useState } from 'react';
import { node } from 'prop-types';

const LoginContext = createContext();

const LoginProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isDisabled = useMemo(
    () => email.length === 0 || password.length === 0,
    [email.length, password.length]
  );

  const value = { isDisabled, email, setEmail, password, setPassword };

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

function useLogin() {
  const context = useContext(LoginContext);
  if (context === undefined) throw new Error('useLogin requires a context!');

  return context;
}

LoginProvider.propTypes = { children: node.isRequired };

export { LoginProvider, useLogin };
